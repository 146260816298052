import AppContext from '@src/context'
import PropTypes from "prop-types"
import React from "react"
// Style Imports
import "./Hero.scss"
// Content Imports

function HomepageHero() {
    return (

        <section className={"hero hero--homepage"}>
            <div className="container">
                <AppContext.Consumer>
                    {({currentLang}) =>
                        currentLang === 'es' ?
                        (<>
                            <h1>Mi vida con <br />nefritis lúpica es <span className="styled-text styled-underline1">complicada</span></h1>
                            <h2><span className="styled-underline2">LUPKYNIS<sup>&reg;</sup></span> me ayuda a <br /><span className="styled-text">centrarme en la lucha</span></h2>
                            <div className="hero__disclaimer">
                                <p>Retrato de actor</p>
                            </div>
                        </>) :
                        (<>
                            <h1>My life with lupus <br />nephritis is <span className="styled-text styled-underline1">complicated</span></h1>
                            <h2><span className="styled-underline2">LUPKYNIS<sup>&reg;</sup></span> helps me <br /><span className="styled-text">focus on the fight</span></h2>
                            <div className="hero__disclaimer">
                                <p>Actor portrayal</p>
                            </div>
                        </>)
                    }
                </AppContext.Consumer>
            </div>
        </section>

    )
}

export default HomepageHero

HomepageHero.propTypes = {
    addedClass: PropTypes.string,
}

HomepageHero.defaultProps = {
    addedClass: '',
}
